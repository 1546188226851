import GATSBY_COMPILED_MDX from "/Users/eswan18/Develop/michaelswan.studio/works/oil/man-on-horse/index.mdx";
import * as React from 'react';
import {PhotoCentricLayout} from '../../layouts';
import {graphql} from 'gatsby';
import Seo from '../../components/seo';
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import styled from "styled-components";
const CaptionText = (styled.p)`
  margin-top: 0;
`;
const Flex = (styled.div)`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const FlexContent = (styled.div)`
  flex: 0 1 auto;
  text-align: center;
`;
const Work = ({data, children}) => {
  const {title, image: rawImage} = data.mdx.frontmatter;
  const image = getImage(rawImage);
  const gatsbyImage = React.createElement(GatsbyImage, {
    image: image,
    alt: title
  });
  return React.createElement(PhotoCentricLayout, {
    pageTitle: title,
    image: gatsbyImage
  }, React.createElement(Flex, null, React.createElement(FlexContent, null, React.createElement(CaptionText, null, children))));
};
export const query = graphql`
  query ($id: String) {
    mdx(id: {eq: $id}) {
      frontmatter {
        title
        medium
        image {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
`;
export const Head = () => React.createElement(Seo, {
  title: "Work"
});
Work
export default function GatsbyMDXWrapper(props) {
  return React.createElement(Work, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
